
import last from "./audio/last.mp3";
import nostrafine from "./audio/nostra fine.mp3";
import take from "./audio/take your time sample chop 7.31.2021.mp3";
import cherry from "./audio/cherry wine 8.18.2021.mp3";
import heaven from "./audio/heaven.mp3";
import what from "./audio/what.mp3";
import retro from "./audio/retro.mp3";
import triforce from "./audio/triforce 8 bit type beat 11.28.2020.mp3";
import blooming from "./audio/blooming flowers and the full moon sample 5.21.2021.mp3";
// import  from "./audio/.mp3";
// import  from "./audio/.mp3";
import grad from './album_covers/graduation.jpg';
import nostra from './album_covers/nostra.jpeg';
import tri from './album_covers/triforce.png';
import w from './album_covers/what.png';
import h from './album_covers/heaven.png';
import r from './album_covers/retro.png';

import moon from './album_covers/moon.png';




// All of these artists are at https://pixabay.com/music/search/mood/laid%20back/
export default [
  {
    title: "heaven knows 8.23.2023",
    audioSrc: heaven,
    image: h,
    color: "#00aeb0"
  },
  {
    title: "what if 7.20.2023",
    audioSrc: what,
    image: w,
    color: "#ffb77a"
  },
  {
    title: "retro 2.22.2022",
    audioSrc: retro,
    image: r,
    color: "#ffb77a"
  },
  {
    title: "triforce 8 bit type beat 11.28.2020",
    audioSrc: triforce,
    image: tri,
    color: "#ffb77a"
  },

];
