import React, { useRef }  from 'react';

export default function Bar(props) {

    return (
        <div 
            className="singleBar" 
            id={props.id} 
            key={props.key}
        >

        </div>
    );

}