import React from 'react';
import HeaderNav from './HeaderNav.js'
import { NavLink } from "react-router-dom";
import tracks from './../music_data/tracks.js'
import mail from './../assets/mail.png';
import fb from './../assets/fb.png';
import ig from './../assets/ig.png';
import linkedin from './../assets/linkedin.png';
import gh from './../assets/github.png';
import twitter from './../assets/twitter.png';
import tiktok from './../assets/tiktok.png';
import youtube from './../assets/youtube.png';


import AudioPlayer from './newPlayer/AudioPlayer';


import './../styles/App.scss';

function Footer(props) {

  return (
      <footer className="container">
            <AudioPlayer tracks={tracks} />
            <div className="social-links">
                <a href="mailto:adhiv@berkeley.edu?subject=Hey%20Adhiv!" target="_blank"><img src={mail} alt="Email" /></a>
                {/* <a href="https://github.com/adhivd" target="_blank"><img src={gh} alt="Github" /></a> */}
                {/* <a href="https://www.linkedin.com/in/adhivd/" target="_blank"><img src={linkedin} alt="LinkedIn" /></a> */}
                <a href="https://www.instagram.com/adhivd/" target="_blank"><img src={ig} alt="Instagram" /></a>
                <a href="https://www.tiktok.com/@adhivd" target="_blank"><img src={tiktok} alt="Tik Tok" /></a>
                <a href="https://www.youtube.com/@dharbeats" target="_blank"><img src={youtube} alt="Youtube" /></a>
                {/* <a href="https://twitter.com/adhivdhar" target="_blank"><img src={twitter} alt="Twitter" /></a> */}
            </div>
      </footer>
  );
}

export default Footer;
