import React, {Component} from 'react';
import HeaderNav from './HeaderNav.js'

import pic1 from './../assets/about_1.png';
import pic2 from './../assets/about_2.png';
import pic3 from './../assets/about_3.png';
import pic4 from './../assets/about_4.png';
import pic5 from './../assets/about_5.png';
import pic6 from './../assets/about_6.png';
import pic7 from './../assets/about_7.png';
import pic8 from './../assets/about_8.png';
import pic9 from './../assets/about_9.png';



import './../styles/App.scss';
import './../styles/About.scss';




class About extends Component {

    state = {
        innerWidth: 0,
        innerHeight: 0,
        blogHovered: false
    }

    


  render() {

    let orgContent = [
        {
            name: "accel scholars",
            timeline: "[2019-2021]",
            description: "Year long program that gathers a community of engineering students at Berkeley",
            links: [
                {
                    link: "https://eecs.berkeley.edu/resources/undergrads/accel",
                    content: "website"
                },
            ]

        },
        {
            name: "free ventures",
            timeline: "[2019-2021]",
            description: "Served as managing director for a pre-seed startup incubator at Berkeley that has helped 81 alumni teams raise over $85    million from top firms such as Y Combinator, Greylock, KPCB, and Accel",
            links: [
                {
                    link: "https://www.freeventures.org/",
                    content: "website"
                },
            ]

        },
        {
            name: "net impact berkeley",
            timeline: "[2018-2021]",
            description: "A consulting club focused on social impact and sustainability.",
            links: [
                {
                    link: "https://nib.berkeley.edu/",
                    content: "website"
                },
            ]

        },
        {
            name: "cal hacks",
            timeline: "[2018-2019]",
            description: "The largest collegiate hackathon in the nation. Led the diversity team and Cubstart, an initiative to introduce newcomers to the hackathon space.",
            links: [
                {
                    link: "https://calhacks.io/",
                    content: "website"
                },
            ]

        },
    ];

    let teachingContent = [
        {
            name: "react decal",
            timeline: "[2019]",
            description: "TA for Berkeley’s first React course, a popular front-end library for building user interfaces.",
            links: [
                {
                    link: "https://www.reactdecal.org/",
                    content: "github"
                },
                {
                    link: "https://portal.reactdecal.org/semesters/1",
                    content: "curriculum"
                },
            ]

        },
        {
            name: "cubstart",
            timeline: "[2018-2019]",
            description: "Initiative from cal hacks to introduce newcomers to the hackathon space. Led the web dev track",
            links: [
                {
                    link: "https://www.notion.so/cubstart/Cubstart-6-0-c413ee6ff102446c981126ccfaea2b7c",
                    content: "curriculum"
                },
                {
                    link: "https://calhacks.io/cubstart",
                    content: "website"
                },
            ]

        },
        {
            name: "ruby on rails decal",
            timeline: "[2018]",
            description: "A web MVC framework for building full-stack applications quickly. Helped build labs and homework assignments for students.",
            links: [
                {
                    link: "https://github.com/rails-decal/",
                    content: "github"
                },
                {
                    link: "https://www.notion.so/Rails-DeCal-66b807a01bdd4de6b75bcd0707a81218",
                    content: "curriculum"
                },
            ]

        },
        
        {
            name: "develop academy",
            timeline: "[2016-2017]",
            description: "A summer camp focused on teaching unique skills not taught under traditional education. Our work received an award from Congress + invited to present at an FUHSD district board meeting",
            links: [
                {
                    link: "https://www.mercurynews.com/2017/05/26/lynbrook-high-school-students-give-back-by-leading-educational-summer-camp/",
                    content: "mercury news"
                },
                {
                    link: "https://web.archive.org/web/20180803202526/http://develop.academy/",
                    content: "archive 2018"
                },
            ]

        },
    ];

    let sideprojectsContent = [
        {
            name: "block action",
            timeline: "[2018-2019]",
            description: "Tokenized class action settlements and distribution built on the Ethereum Blockchain. Accepted into VC firm Skydeck’s Hotdesk program",
            links: [
                {
                    link: "http://blockaction.herokuapp.com/",
                    content: "website"
                },
                {
                    link: "https://github.com/adhivd/block-action",
                    content: "github"
                },
                {
                    link: "https://skydeck.berkeley.edu/",
                    content: "skydeck"
                },
            ]

        },
        {
            name: "hibernate",
            timeline: "[2018]",
            description: "A housing and subletting platform for/by berkeley students.",
            links: [
                {
                    link: "https://hibernate-cal.herokuapp.com/",
                    content: "website"
                },
                {
                    link: "https://github.com/adhivd/rails-final-project",
                    content: "github"
                },
            ]

        },
        {
            name: "self check",
            timeline: "[2016-2017]",
            description: "iOS app to assist + remind people to conduct skin self examinations.",
        },
        {
            name: "hs campaign",
            timeline: "[2015-2018]",
            description: "A web app made to make student council elections fair and equitable. Sold a subscription deal to our high school.",
            links: [
                {
                    link: "https://web.archive.org/web/20180309220455/https://hs-campaign.com/",
                    content: "archive 2018"
                },
            ]

        },
        {
            name: "action",
            timeline: "[2014-2017]",
            description: "Meeting follow-up app embedded into Google Docs. ",
            links: [
                {
                    link: "https://www.producthunt.com/posts/action",
                    content: "product hunt"
                },
                {
                    link: "https://web.archive.org/web/20170521022925/https://meetaction.com/",
                    content: "archive 2017"
                },
                {
                    link: "https://medium.com/1517/launching-on-product-hunt-a-high-schooler-s-experience-ca836b40e21b",
                    content: "medium"
                }
            ]

        },
        {
            name: "classfinder",
            timeline: "[2010-2017]",
            description: "A class-finding app + social network. Over the years, we expanded to 5 schools + thousands of users.",
            links: [
                {
                    link: "https://web.archive.org/web/20120702154924/http://classfinder.me/",
                    content: "archive 2012"
                },
                {
                    link: "https://web.archive.org/web/20170402153325/http://classfinder.me/",
                    content: "archive 2017"
                }
            ]

        },
        
    ];

    // yeahhhh this code fuckin sucks
    let sideprojects = sideprojectsContent.map((x) => {

        let links;
        if(x.links) {
            links = x.links.map((l, i, arr) => {
                let comma;
                if (arr.length - 1 !== i) {
                    comma = ", "
                }
                
                return (<span><a href={l.link} target="_blank" >{l.content}</a>{comma}</span>);
            })
        }

        let linkDisplay;

        if(links) {
            linkDisplay = <span class="gray"> [{links}]</span>
        }

        return (<li><b>{x.name}</b>  <span class="gray">{x.timeline}</span> {x.description} 
                    {linkDisplay}
                </li>);
    });

    let teaching = teachingContent.map((x) => {

        let links;
        if(x.links) {
            links = x.links.map((l, i, arr) => {
                let comma;
                if (arr.length - 1 !== i) {
                    comma = ", "
                }
                
                return (<span><a href={l.link} target="_blank" >{l.content}</a>{comma}</span>);
            })
        }

        let linkDisplay;

        if(links) {
            linkDisplay = <span class="gray"> [{links}]</span>
        }

        return (<li><b>{x.name}</b>  <span class="gray">{x.timeline}</span> {x.description} 
                    {linkDisplay}
                </li>);
    });

    let orgs = orgContent.map((x) => {

        let links;
        if(x.links) {
            links = x.links.map((l, i, arr) => {
                let comma;
                if (arr.length - 1 !== i) {
                    comma = ", "
                }
                
                return (<span><a href={l.link} target="_blank" >{l.content}</a>{comma}</span>);
            })
        }

        let linkDisplay;

        if(links) {
            linkDisplay = <span class="gray"> [{links}]</span>
        }

        return (<li><b>{x.name}</b>  <span class="gray">{x.timeline}</span> {x.description} 
                    {linkDisplay}
                </li>);
    });


      return (
        <section className="about container">
            <HeaderNav home={true} blogHandlerEnter={this.blogHandlerEnter} blogHandlerLeave={this.blogHandlerLeave} />
            
            <div class="content">
                <div class="photos">
                    <img src={pic7} />
                    <img src={pic8} />
                    <img src={pic9} />
                </div>
                <p class="caption">
                    long exposure portrait, shot by lily / somewhere in montana / still from TINO visuals, shot by emil
                </p>
                <h3>about me</h3>
                <p>My name is adhiv dhar. I live in brooklyn and I enjoy making stuff. I'm currently producing music and taking on freelance web development projects. I graduated from uc berkeley with a degree in eecs and previously did product at twitter</p>
                <p>I spend most of my time creating stuff. When I'm not doing that, I go on walks, journal, cook, meet friends for drinks, and listen to music (james blake, kendrick lamar, troy boi, flume, mac miller, jai paul). born and raised in the bay area and intensely missing my dog</p>
                <h3>my interests</h3>
                <ul>
                    <li><b>music production</b>. I'm obsessed with music. I'm currently focused on hip hop + electronic music. I just released TINO – a beat tape sampling 1970's bollywood. Previously made tons of type beats. Love sampling soul, orchestra, and video game music.
                        <span class="links"><br /> [ <a href="https://www.youtube.com/@dharbeats" target="_blank">youtube</a>, <a href="https://soundcloud.com/dharbeats" target="_blank">soundcloud</a>, <a href="https://www.youtube.com/watch?v=SkyfCY1tUwA" target="_blank">TINO</a> ]</span>
                    </li>
                    <li><b>disc jockey-ing</b>. To be real with you, I DJ because I like to see how my + my friends' original music is received on a dance floor. It's also my preferred thing to do at parties (and way more fun than going to a club w/ mid music). You can hire me n my friends to come DJ ur party (no joke, DM me).
                        <span class="links"><br /> [ <a href="https://www.instagram.com/3rdcltr" target="_blank">third culture</a> ]</span>
                    
                    </li>
                    <li><b>web development</b>. I've been designing and building websites since age 11. I'm a full stack developer, though building front-ends are a lot more fun. Some recent ones:
                        <span class="links"><br /> [ <a href="https://github.com/adhivd" target="_blank">github</a>, <a href="https://nib.berkeley.edu/" target="_blank">net impact</a>, <a href="https://www.freeventures.org/" target="_blank">free ventures</a>, adhiv.com ]</span>
                    </li>
                    <li><b>collage + multimedia art</b>. Collaging is to visual art as sampling is to hip hop. I love taking real, physical things, and mixing em together with paint and hot glue on a canvas. (will find a way to post these somewhere). I also love internet art + built a web moodboarding tool for myself called mindgarden.
                    <span class="links"><br /> [ <a href="https://mindgarden.page/" target="_blank">mind garden</a> ]</span>
                    </li>
                       

                    
                    <li><b>dance</b>. I enjoy both dancing and choreographing to various types of music. Used to do AFX at Berkeley and danced in various high school performances. These days it mainly happens during my friends' DJ sets + occasional club dance floors</li>
                </ul>
                <p class="caption">
                    me in my native environment (a cafe w friends) / painting on our walls / behind the bar @ fiction
                </p>
                <div class="photos">
                    <img src={pic1} />
                    <img src={pic2} />
                    <img src={pic3} />
                </div>
                
                <p class="caption">Last updated July 1st, 2024</p>

                

                
                
            </div>
            

          </section>
          
      );
  }
}

export default About;
