import React, {Component} from 'react';
import ProgressBar from '@material-ui/core/LinearProgress';

class Progress extends Component {

      
    
      render() {

        return (
        <div className="progressBarContainer">
            <ProgressBar variant="determinate" value={this.props.progress} />
        </div>
        );
      }
}

export default Progress;
