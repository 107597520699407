import React, { useRef }  from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Bar from './Bar';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: '25%'
  }
}));

class Visual extends React.Component {

	constructor(props) {
		super(props);
		this.childRef = React.createRef();
		this.amplitudeValues = React.createRef();
	}

    // const amplitudeValues = useRef(null);

    adjustFreqBandStyle = (newAmplitudeData) => {
        this.amplitudeValues.current = newAmplitudeData;
        let domElements = this.props.frequencyBandArray.map((num) =>
        	document.getElementById(num));

        for(let i=0; i<this.props.frequencyBandArray.length; i++){
            let num = this.props.frequencyBandArray[i]
            // domElements[num].style.backgroundColor = `rgb(254, 68, ${amplitudeValues.current[num]})`;
            domElements[num].style.backgroundColor = `rgb(254, 68, 133)`;
			let height = Math.max(7, this.amplitudeValues.current[num] / 2);
			domElements[num].style.height = `${height}px`
			


        }
    };

    runSpectrum = () => {
      this.props.getFrequencyData(this.adjustFreqBandStyle)
      this.props.accumulateData();
      requestAnimationFrame(this.runSpectrum)
    }

    handleStartBottonClick = () => {
    //   props.initializeAudioAnalyser()
      requestAnimationFrame(this.runSpectrum)
	}
	
	render() {
		return (
			  <div class="collectedBars">
				  {this.props.frequencyBandArray.map((num) =>
					  <Bar
						  id={num}
						  key={num}
					  />
				  )}
			  </div>
		  );

	}

    

}

export default Visual;