import React, {Component} from 'react';
import HeaderNav from './HeaderNav.js'

import pic1 from './../assets/about_1.png';
import pic2 from './../assets/about_2.png';
import pic3 from './../assets/about_3.png';




import './../styles/App.scss';
import './../styles/About.scss';




class About extends Component {

    state = {
        innerWidth: 0,
        innerHeight: 0,
        blogHovered: false
    }

    


  render() {

    let orgContent = [
        {
            name: "accel scholars",
            timeline: "2020 – 2021",
            description: "Year long program that gathers a community of engineering students at Berkeley",
            links: [
                {
                    link: "https://eecs.berkeley.edu/resources/undergrads/accel",
                    content: "website"
                },
            ]

        },
        {
            name: "free ventures",
            timeline: "2019 – 2021",
            description: "Served as managing director for a pre-seed startup incubator at Berkeley that has helped 81 alumni teams raise over $85    million from top firms such as Y Combinator, Greylock, KPCB, and Accel",
            links: [
                {
                    link: "https://www.freeventures.org/",
                    content: "website"
                },
            ]

        },
        {
            name: "net impact berkeley",
            timeline: "2018 – 2021",
            description: "A consulting club focused on social impact and sustainability.",
            links: [
                {
                    link: "https://nib.berkeley.edu/",
                    content: "website"
                },
            ]

        },
        {
            name: "cal hacks",
            timeline: "2018 – 2019",
            description: "The largest collegiate hackathon in the nation. Led the diversity team and Cubstart, an initiative to introduce newcomers to the hackathon space.",
            links: [
                {
                    link: "https://calhacks.io/",
                    content: "website"
                },
            ]

        },
    ];

    let teachingContent = [
        {
            name: "react decal",
            timeline: "2019",
            description: "TA for Berkeley’s first React course, a popular front-end library for building user interfaces.",
            links: [
                {
                    link: "https://github.com/reactdecal",
                    content: "github"
                },
                {
                    link: "https://web.archive.org/web/20190730132714/https://reactdecal.org/",
                    content: "website"
                },
            ]

        },
        {
            name: "cubstart",
            timeline: "2018 – 2019",
            description: "Initiative from cal hacks to introduce newcomers to the hackathon space. Led the web dev track",
            links: [
                {
                    link: "https://www.notion.so/cubstart/Cubstart-6-0-c413ee6ff102446c981126ccfaea2b7c",
                    content: "curriculum"
                },
                {
                    link: "https://www.cubstart.com/",
                    content: "website"
                },
            ]

        },
        {
            name: "ruby on rails decal",
            timeline: "2018",
            description: "A web MVC framework for building full-stack applications quickly. Helped build labs and homework assignments for students.",
            links: [
                {
                    link: "https://github.com/rails-decal/",
                    content: "github"
                },
                {
                    link: "https://www.notion.so/Rails-DeCal-66b807a01bdd4de6b75bcd0707a81218",
                    content: "curriculum"
                },
            ]

        },
        
        {
            name: "develop academy",
            timeline: "2016 – 2017",
            description: "A summer camp focused on teaching unique skills not taught under traditional education. Our work received an award from Congress + invited to present at an FUHSD district board meeting",
            links: [
                {
                    link: "https://www.mercurynews.com/2017/05/26/lynbrook-high-school-students-give-back-by-leading-educational-summer-camp/",
                    content: "mercury news"
                },
                {
                    link: "https://web.archive.org/web/20180803202526/http://develop.academy/",
                    content: "archive 2018"
                },
            ]

        },
    ];

    let sideprojectsContent = [
        {
            name: "mind garden",
            timeline: "2021 – now",
            description: "(wip) unopinionated internet moodboards. Built on node, react, and firebase. May be partially broken, use at own risk",
            links: [
                {
                    link: "http://mindgarden.page/",
                    content: "website"
                },
            ]

        },
        {
            name: "block action",
            timeline: "2018 – 2019",
            description: "Tokenized class action settlements and distribution built on the Ethereum Blockchain. Accepted into VC firm Skydeck’s Hotdesk program",
            links: [
                {
                    link: "https://github.com/adhivd/block-action",
                    content: "github"
                },
                {
                    link: "https://skydeck.berkeley.edu/",
                    content: "skydeck"
                },
            ]

        },
        {
            name: "hibernate",
            timeline: "2018",
            description: "A housing and subletting platform for/by berkeley students.",
            links: [
                {
                    link: "https://github.com/adhivd/rails-final-project",
                    content: "github"
                },
            ]

        },
        {
            name: "self check",
            timeline: "2016 – 2017",
            description: "iOS app to assist + remind people to conduct skin self examinations.",
        },
        {
            name: "hs campaign",
            timeline: "2015 – 2018",
            description: "A web app made to make student council elections fair and equitable. Sold a subscription deal to our high school.",
            links: [
                {
                    link: "https://web.archive.org/web/20180309220455/https://hs-campaign.com/",
                    content: "archive 2018"
                },
            ]

        },
        {
            name: "action",
            timeline: "2014 – 2017",
            description: "Meeting follow-up app embedded into Google Docs.",
            links: [
                {
                    link: "https://www.producthunt.com/posts/action",
                    content: "product hunt"
                },
                {
                    link: "https://web.archive.org/web/20170521022925/https://meetaction.com/",
                    content: "archive 2017"
                },
                {
                    link: "https://medium.com/1517/launching-on-product-hunt-a-high-schooler-s-experience-ca836b40e21b",
                    content: "medium"
                }
            ]

        },
        {
            name: "classfinder",
            timeline: "2010 – 2017",
            description: "A class-finding app + social network. Over the years, we expanded to 5 schools + thousands of users.",
            links: [
                {
                    link: "https://web.archive.org/web/20120702154924/http://classfinder.me/",
                    content: "archive 2012"
                },
                {
                    link: "https://web.archive.org/web/20170402153325/http://classfinder.me/",
                    content: "archive 2017"
                }
            ]

        },
        
    ];

    // yeahhhh this code fuckin sucks
    let sideprojects = sideprojectsContent.map((x) => {

        let links;
        if(x.links) {
            links = x.links.map((l, i, arr) => {
                let comma;
                if (arr.length - 1 !== i) {
                    comma = ", "
                }
                
                return (<span><a href={l.link} target="_blank" >{l.content}</a>{comma}</span>);
            })
        }

        let linkDisplay;

        if(links) {
            linkDisplay = <span class="links"><br /> [ {links} ]</span>
        }

        return (<li><b>{x.name}</b>  <span class="gray">{x.timeline}</span><br /> {x.description} 
                    {linkDisplay}
                </li>);
    });

    let teaching = teachingContent.map((x) => {

        let links;
        if(x.links) {
            links = x.links.map((l, i, arr) => {
                let comma;
                if (arr.length - 1 !== i) {
                    comma = ", "
                }
                
                return (<span><a href={l.link} target="_blank" >{l.content}</a>{comma}</span>);
            })
        }

        let linkDisplay;

        if(links) {
            linkDisplay = <span class="links">< br/> [ {links} ]</span>
        }

        return (<li><b>{x.name}</b>  <span class="gray">{x.timeline}</span><br /> {x.description} 
                    {linkDisplay}
                </li>);
    });

    let orgs = orgContent.map((x) => {

        let links;
        if(x.links) {
            links = x.links.map((l, i, arr) => {
                let comma;
                if (arr.length - 1 !== i) {
                    comma = ", "
                }
                
                return (<span><a href={l.link} target="_blank" >{l.content}</a>{comma}</span>);
            })
        }

        let linkDisplay;
        

        if(links) {
            linkDisplay = <span class="links"><br /> [ {links} ]</span>
        }

        return (<li><b>{x.name}</b>  <span class="gray">{x.timeline}</span><br /> {x.description} 
                    {linkDisplay}
                </li>);
    });

    let reactText = "<Tweet />"


      return (
        <section className="about container">
            <HeaderNav home={true} blogHandlerEnter={this.blogHandlerEnter} blogHandlerLeave={this.blogHandlerLeave} />
            
            <div class="content">
                <h3 class="description">places I've worked</h3>
                <ul>
                    <li>
                        <b>fiction bar / cafe</b> <span class="gray">// barista + bartender, brooklyn </span><br />Worked as a part-time barista + bartender. Made coffee, drinks, cold brew, sandwiches + bagels, etc. Consulted with owner on building an e-commerce business and learned to make a killer vanilla latte<span class="links"><br /> [ <a href="https://goo.gl/maps/YVRLwSGcdMysk6f98" target="_blank">google maps</a>, <a href="https://www.bkspeedcoffee.com/pages/location" target="_blank">bkspeed coffee</a> ]</span>
                    </li>
                    <li>
                        <b>twitter</b> <span class="gray">// product manager, [conversation quality, web ui, health ux], remote </span><br /> Drove product requirements for new health feature to proactively surface safety period for users facing targeted, scaled abuse. Worked as a platform pm to standardize re-usable React components for web surfaces. Experimented with recommending related tweets on conversations. Submitted a bug fix for a spacing issue on the {reactText}<span class="links"><br /> [ <a href="https://help.x.com/en/safety-and-security/safety-mode" target="_blank">safety mode</a>, <a href="https://twitter.com/andrs/status/1503721232688160774" target="_blank">tweet component</a> ]</span>
                    </li>
                    <li>
                        <b>cisco</b> <span class="gray">// swe intern, internet of things team, san jose </span><br /> Built a microservice for managing role access of Control Center (their software to manage IoT devices).
                    </li>
                    <li>
                        <b>california path</b>  <span class="gray">// swe intern, berkeley </span><br /> PATH is a Berkeley lab doing advanced transportation research. Built a mobile app using React Native in a team of 3 to allow people to search for local bus stops, find ETAs, and track trip mileage.
                        <span class="links"><br /> [ <a href="https://path.berkeley.edu/about" target="_blank">website</a> ]</span>
                    </li>
                    <li>
                        <b>missionU</b>  <span class="gray">// data engineering intern, san francisco </span><br />A data science education bootcamp (acquired). Developed curriculum based on Python, SQL, and Spreadsheets. Got an inside look into the fast paced ecosystem of a startup.
                        <span class="links"><br /> [ <a href="https://www.crunchbase.com/organization/missionu" target="_blank">website</a> ]</span>
                    </li>
                </ul>

                <h3>organizations</h3>
                <ul>
                    {orgs}
                </ul>

                <h3>side projects</h3>
                <ul>
                    {sideprojects}
                </ul>

                <h3>teaching</h3>
                <ul>
                    {teaching}
                </ul>
                
                <p class="caption">Made this website using React + Netlify. Last updated February 14th, 2023</p>

                

                
                
            </div>
            

          </section>
          
      );
  }
}

export default About;
