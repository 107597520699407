import React, { useState, useEffect, useRef } from "react";
import  AudioControls  from "./AudioControls";
import spotify from './../../assets/spotify.png';


/*
 * Huge thank you to this article:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */

import './../../styles/AudioPlayer.scss';


const AudioPlayer = ({ tracks }) => {
  // // State
  // const [trackIndex, setTrackIndex] = useState(0);
  // const [trackProgress, setTrackProgress] = useState(0);
  // const [isPlaying, setIsPlaying] = useState(false);

  // // Destructure for conciseness
  // const { title, artist, color, image, audioSrc } = tracks[trackIndex];

  // // Refs
  // const audioRef = useRef(new Audio(audioSrc));
  // const intervalRef = useRef();
  // const isReady = useRef(false);

  // // Destructure for conciseness
  // const { duration } = audioRef.current;

  // const currentPercentage = duration
  //   ? `${(trackProgress / duration) * 100}%`
  //   : "0%";
  // const trackStyling = `
  //   -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #9D9EEC), color-stop(${currentPercentage}, #EAEAEA))
  // `;

  // const startTimer = () => {
  //   // Clear any timers already running
  //   clearInterval(intervalRef.current);

  //   intervalRef.current = setInterval(() => {
  //     if (audioRef.current.ended) {
  //       toNextTrack();
  //     } else {
  //       setTrackProgress(audioRef.current.currentTime);
  //     }
  //   }, [1000]);
  // };

  // const onScrub = (value) => {
  //   // Clear any timers already running
  //   clearInterval(intervalRef.current);
  //   audioRef.current.currentTime = value;
  //   setTrackProgress(audioRef.current.currentTime);
  // };

  // const onScrubEnd = () => {
  //   // If not already playing, start
  //   if (!isPlaying) {
  //     setIsPlaying(true);
  //   }
  //   startTimer();
  // };

  // const toPrevTrack = () => {
  //   if (trackIndex - 1 < 0) {
  //     setTrackIndex(tracks.length - 1);
  //   } else {
  //     setTrackIndex(trackIndex - 1);
  //   }
  // };

  // const toNextTrack = () => {
  //   if (trackIndex < tracks.length - 1) {
  //     setTrackIndex(trackIndex + 1);
  //   } else {
  //     setTrackIndex(0);
  //   }
  // };

  // useEffect(() => {
  //   if (isPlaying) {
  //     audioRef.current.play();
  //     startTimer();
  //   } else {
  //     audioRef.current.pause();
  //   }
  // }, [isPlaying]);

  // // Handles cleanup and setup when changing tracks
  // useEffect(() => {
  //   audioRef.current.pause();

  //   audioRef.current = new Audio(audioSrc);
  //   setTrackProgress(audioRef.current.currentTime);

  //   if (isReady.current) {
  //     audioRef.current.play();
  //     setIsPlaying(true);
  //     startTimer();
  //   } else {
  //     // Set the isReady ref as true for the next pass
  //     isReady.current = true;
  //   }
  // }, [trackIndex]);

  // useEffect(() => {
  //   // Pause and clean up on unmount
  //   return () => {
  //     audioRef.current.pause();
  //     clearInterval(intervalRef.current);
  //   };
  // }, []);

  // let progressBarClass = "progress"
  // let trackInfoClass = "track-info"
	// if(isPlaying) {
	// 	progressBarClass += " visible";
	// 	trackInfoClass += " visible";
	// }

  return (
    <div className="audio-player">
      <a href="https://distrokid.com/hyperfollow/adhivdharxthirdculture/your-orbit" target="_blank" >
        <div className="spotifyButton">
        <img src={spotify} alt="Spotify Logo" />
            <p>pre-save your orbit</p>
        </div>
      </a>
      
		{/* <div class="audio-controls" >
			<AudioControls
				isPlaying={isPlaying}
				onPrevClick={toPrevTrack}
				onNextClick={toNextTrack}
				onPlayPauseClick={setIsPlaying}
			/>
			<input
				type="range"
				value={trackProgress}
				step="1"
				min="0"
				max={duration ? duration : `${duration}`}
				className={progressBarClass}
				onChange={(e) => onScrub(e.target.value)}
				onMouseUp={onScrubEnd}
				onKeyUp={onScrubEnd}
				style={{ background: trackStyling }}
			/>
		</div>
		<div className={trackInfoClass}>
			<img
			className="artwork"
			src={image}
			alt={`track artwork for ${title} by ${artist}`}
			/>
			<h2 className="title">{title}</h2>
		</div> */}
    </div>
  );
};

export default AudioPlayer;
