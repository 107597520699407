import React, {Component} from 'react';
import HeaderNav from './HeaderNav.js'


import './../styles/App.scss';
import './../styles/About.scss';

class Writing extends Component {

    state = {
        innerWidth: 0,
        innerHeight: 0,
        blogHovered: false
    }

  render() {


      return (
        <section className="about container">
            <HeaderNav home={true} blogHandlerEnter={this.blogHandlerEnter} blogHandlerLeave={this.blogHandlerLeave} />
            
            <div class="content">
                
                <p class="caption">
                    some writing by me
                </p>
                <h3>posts by adhiv</h3>

                <iframe src="//soundcloud.com/dharbeats" 
                title="okay" width={'1000px'} height={'400px'}></iframe>
                
                
                <p class="caption">Last updated November 29th, 2023</p>
                
            </div>
            

          </section>
          
      );
  }
}

export default Writing;
