import React from 'react';
import Home from './Components/Home.js';
import About from './Components/About.js';
import Portfolio from './Components/Portfolio.js';
import Music from './Components/Music.js';
import Writing from './Components/Writing.js';

import Now from './Components/Now.js';
import GA from './Components/GoogleAnalytics';
import Animation from './Components/Animation';
import AudioPlayer from './Components/newPlayer/AudioPlayer';
import Footer from './Components/Footer';


import { BrowserRouter as Router, Route } from "react-router-dom";

import './styles/App.scss';

function App() {

  let threedworld = <Animation />

  return (
      <Router>
          {/* <p><!-- Big thanks to Jonathan Yang + Shiva Vemireddy for looking over iterations of this <3  --></p> */}
          { GA.init() && <GA.RouteTracker /> }
          <Route exact path="/" component={Home} />
          <Route path="/now" component={Now} />
          <Route path="/about" component={About} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/music" component={Music} />
          <Route path="/writing" component={Writing} />
          {threedworld}
          <Footer />
      </Router>
  );
}

export default App;
