import React from 'react';
import { NavLink } from "react-router-dom";

import './../styles/App.scss';
import './../styles/glitch.scss';
    
function Header(props) {
    let navClass = "nav"
    if(props.home) {
        navClass += " homeNav floatLeft"
    }

    // let glitch = () => {
	// 	ads = document.getElementsByClassName(ad)
	// 	ad[1].classList.add("glitch");
    // }


  return (
    <div className="nav floatLeft">
        <ul>
            {/* <li class="ad" >
				<div>
					<h1 class="yo">
						<span class="bro">
							<span class="so">adhiv</span>
						</span>
					</h1>	
				</div>
			</li>	 */}
            <li><NavLink exact to="/" activeClassName="activeLinkStyle" className="bolder" ><span class="glitch" data-text="adhiv">adhiv</span></NavLink></li>
            <li><NavLink exact to="/now" activeClassName="activeLinkStyle" >now</NavLink></li>
            <li><NavLink to="/about" activeClassName="activeLinkStyle" >about</NavLink></li>
            <li><NavLink to="/portfolio" activeClassName="activeLinkStyle" >portfolio</NavLink></li>
            {/* <li><NavLink to="/music" className="smooth" activeClassName="activeLinkStyle" >music</NavLink></li> */}
            {/* <li><a href="https://mindgarden.page/adhiv"
                    target="_blank"
                    className="airplane"
                    activeClassName="activeLinkStyle"
                    onMouseEnter={props.blogHandlerEnter}
                    onMouseLeave={props.blogHandlerLeave}
                >garden</a></li> */}
        </ul>
    </div>
  );
}

export default Header;
