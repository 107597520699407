import React, {Component} from 'react';
import HeaderNav from './HeaderNav.js'
import external from './../assets/external-link.png';


import './../styles/App.scss';
import pic1 from './../assets/about_1.png';
import pic2 from './../assets/about_2.png';
import pic3 from './../assets/about_3.png';



class Now extends Component {

    state = {
        innerWidth: 0,
        innerHeight: 0,
        blogHovered: false
    }

  render() {

      return (
          <section className="about container">
            <HeaderNav home={true} blogHandlerEnter={this.blogHandlerEnter} blogHandlerLeave={this.blogHandlerLeave} />
            <div class="content">
                <h3 class="description">what I'm currently up to</h3>
                <ul>
                    <li>looking for contract / part-time tech work. if you're looking for a web developer / designer, you can reach me at adhiv [at] berkeley [dot] edu.</li>
                    <li>releasing my first beat tape "TINO", sampling 1970s bollywood + indian classical music: listen on <a href="https://soundcloud.com/dharbeats/sets/tino" target="_blank">soundcloud</a>, watch on <a href="https://youtu.be/SkyfCY1tUwA" target="_blank">youtube</a></li>
                    <li>releasing music with third culture: <a href="https://open.spotify.com/album/4utgu6dXSYsibPV6I15St8" target="_blank">stop by sometime</a>, an indie x hip hop project. <a href="https://open.spotify.com/album/0ggKa7a1Lb3nxmC79a7PgM" target="_blank">so far gone</a>, an electronic DnB song. </li>
                    <li>i'm DJing more often, mainly for events + parties. it's a great way to mix in the music I'm making into a set of music it was inspired by and see how well it fits. I'm also making more remixes (especially electronic)</li>
                    <li>slowly putting the puzzle pieces together for an electronic x hip hop project</li>
                    <p class="caption">Inspired by Derek Sivers️’ <a href="https://nownownow.com/about" target="_blank">/now</a>. Last updated July 1st, 2024</p>
                </ul>
            </div>
            

          </section>
          
      );
  }
}

export default Now;
