import React, { useRef }  from 'react';

export default function Album(props) {

    return (
        <div className="albumImage">
            {/* <img src={props.image} /> */}
            <p>insert album cover here</p>
        </div>
    );

}