import React from "react";
import Play from "./../../assets/player/play.png";
import Pause from "./../../assets/player/pause.png";
import Next from "./../../assets/player/next.png";
import Prev from "./../../assets/player/prev.png";
import { Popup } from 'semantic-ui-react'

import './../../styles/AudioPlayer.scss';
import { withTheme } from "@material-ui/core";


function AudioControls({
	isPlaying,
	onPlayPauseClick,
	onPrevClick,
	onNextClick
})  {

	return (
		<div className="buttons">
			<button
				type="button"
				className="prev"
				aria-label="Previous"
				onClick={onPrevClick}
			>
				<img src={Prev} />
			</button>
			{isPlaying ? (
				<button
					type="button"
					className="playpause"
					onClick={() => onPlayPauseClick(false)}
					aria-label="Pause"
				>
					<img src={Pause} />
				</button>
			) : (
				<button
					type="button"
					className="playpause play"
					onClick={() => onPlayPauseClick(true)}
					aria-label="Play"
				>
					<img src={Play} />
				</button>
			)}
			<button
				type="button"
				className="next"
				aria-label="Next"
				onClick={onNextClick}
			>
				<img src={Next} />
			</button>
		</div>
	);

}

// const AudioControls = ({
//   isPlaying,
//   onPlayPauseClick,
//   onPrevClick,
//   onNextClick
// }) => (
//   <div className="audio-controls">
//     <button
//       type="button"
//       className="prev"
//       aria-label="Previous"
//       onClick={onPrevClick}
//     >
//       <Prev />
//     </button>
//     {isPlaying ? (
//       <button
//         type="button"
//         className="pause"
//         onClick={() => onPlayPauseClick(false)}
//         aria-label="Pause"
//       >
//         <Pause />
//       </button>
//     ) : (
//       <button
//         type="button"
//         className="play"
//         onClick={() => onPlayPauseClick(true)}
//         aria-label="Play"
//       >
//         <Play />
//       </button>
//     )}
//     <button
//       type="button"
//       className="next"
//       aria-label="Next"
//       onClick={onNextClick}
//     >
//       <Next />
//     </button>
//   </div>
// );

export default AudioControls;
