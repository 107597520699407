import React, {Component} from 'react';
import Visual from './audioComponents/Visual';
import Progress from './audioComponents/Progress';
import Album from './audioComponents/Album';
import kanye from './../music_data/album_covers/graduation.jpg';
import Animation from './Animation';
import playButton from './../assets/play_icon.png';
import pauseButton from './../assets/pause_icon.png';


import example from './../music_data/audio/last.mp3'

import './../styles/App.scss';
import './../styles/Music.scss';



class Music extends Component {
    
	constructor(props) {
		super(props);
		this.state = {}
		this.frequencyBandArray = [...Array(24).keys()]
		this.child = React.createRef();
	}

	initializeAudioAnalyser = () => {
		console.log("3. enter initaudioanalyzser", "child", this.child);
		const audioFile = new Audio();
		const audioContext = new AudioContext();
		const source = audioContext.createMediaElementSource(audioFile);
		const analyser = audioContext.createAnalyser();
		audioFile.src = example;
		analyser.fftSize = 64
		source.connect(audioContext.destination);
		source.connect(analyser);
		audioFile.play()
		this.setState({
			audioData: analyser,
			audioFile,
			playing: true,
		}, () => {
			console.log("5. finished setting state with audio file", this.state, this.child);
			this.callbackAfterInit();
		})
	}

	callbackAfterInit = () => {
		this.child.current.handleStartBottonClick();
	}

	togglePlayback = () => {
		console.log("1. toggled start ", this.state);
		if(this.state.playing) {
			this.pausePlayback();
		}
		else {
			this.startPlayback();
		}
		console.log("toggle end", this.state);
		
	}

	pausePlayback = () => {
		this.state.audioFile.pause();
		this.setState({
			playing: false,
		});
	}

	startPlayback = () => {
		console.log("2. toggled ", this.state);
		if(!this.state.audioFile) {
			this.initializeAudioAnalyser();
		}
				
	}

	getFrequencyData = (styleAdjuster) => {
		const bufferLength = this.state.audioData.frequencyBinCount;
		const amplitudeArray = new Uint8Array(bufferLength);
		this.state.audioData.getByteFrequencyData(amplitudeArray)
		styleAdjuster(amplitudeArray)
	}

	accumulateData = () => {
		let length = this.state.audioFile.duration;
		let current = this.state.audioFile.currentTime;
		let progress = current / length * 100
		this.setState({
			audioProgress: progress
		})
	}

  componentDidMount() {
	  document.body.style.background = 'linear-gradient(125deg, #41b5c8, #8041c8)';
	  document.body.style.backgroundSize = '400% 400%';
  }



      render() {

		let animationToggle = <Animation activateAnimation={this.props.location.pathname}/>

		let progress = 0;
		let actionButtonImage;
        if(this.state.playing) { 
			let length = this.state.audioFile.duration;
			let current = this.state.audioFile.currentTime;
			progress = length;

			actionButtonImage = <img src={pauseButton} />;
		}
		else {
			actionButtonImage = <img src={playButton} />;
		}

        return (
			<div id="player">
				<Album 
					// image={kanye}
				/>
				
				<Visual
					initializeAudioAnalyser={this.initializeAudioAnalyser}
					frequencyBandArray={this.frequencyBandArray}
					getFrequencyData={this.getFrequencyData}
					audioData={this.state.audioData}
					accumulateData={this.accumulateData}
					playing={this.state.playing}
					ref={this.child}
				/>
				<div className="info">
					<h1>
						<i>last_one_up.mp3</i>
					</h1>
				</div>
				<div 
					className="playPauseContainer"
					onClick={() => this.togglePlayback()}
				>
					{actionButtonImage}
				</div>
				<Progress 
					progress={this.state.audioProgress}
				/>
				{animationToggle}
			</div>
        );
      }
}

export default Music;
